<template>
  <v-layout wrap >
    <v-flex xs8 style="padding: 0px 10px 20px 20px;">
      <list
        :items="surveys"
        :onEdit="handleEdit"
      />
    </v-flex>
    <v-flex xs4 style="padding: 20px 20px 20px 10px">
      <v-card
        style="min-height:calc(100vh - 104px);"
      >
        <v-card-text>
          <no-edited
            v-if="!editedItem"
            :locale="locale"
            :onAddMenu="handleAddMenu"
          />
          <survey-form 
            v-else-if="editedItem"
            :item="editedItem" 
            :onClose="handleClose"
            :workspaceID="workspaceID"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import NoEdited from './NoEdited'
import List from './List'
import SurveyForm from './SurveyForm'
export default {
  components: {
    NoEdited,
    List,
    SurveyForm,
  },
  data: () => ({
    loading: false,
    editedItem: null,
    surveys: [],
    count: 0,
    itemsPerPage: 1000,
    startIndex: 0,

    updateData: null,
    permissions: null,
    main: 'aa5dd237-9e2a-401c-8b7c-0866e9486428',
    showAll: false,
    defaultItem: {
      ID: null,
      Label: {
        es: '',
        en: '',
        fr: '',
        de: ''
      },
      Hide: false,
    }
  }),
  computed: {
    ...mapState('app',['locale', 'turismoWorkspace']),
    workspaceID () {
      return this.turismoWorkspace
    },
  },
  watch: {
    'turismoWorkspace' () {
      this.handleGetData(0)
    },
  },
  mounted () {
    this.handleGetData(0)
  },
  methods: {
    handleGetData (startIndex) {
      this.surveys = []
      this.loading = true
      if (startIndex >=0 ) this.startIndex = startIndex
      api.getAll ('turismo', 'v1/private/surveys/', this.startIndex, this.itemsPerPage, '')
        .then(response => {             
          this.count = response.count
          this.surveys = response.data.map(item => {
            item.ident = {
              ID: item.ID,
              Name: utils.getTranslation(item.Label, this.locale)
            }
            item.Label = JSON.parse(item.Label)
            return item
          })
          this.loading = false
        })
    },
    handleGetItem (v) {
      api.getItem ('turismo', 'v1/private/surveys/', v)
        .then(response => {   
          response.Label = JSON.parse(response.Label)
          this.editedItem = response
        })
    },
    handleEdit (v) {
      this.handleGetItem(v)
    },
    handleClose (action, updateItem) {
      if (action && updateItem) this.handleGetData(0)
/*        this.updateData = {
          action,
          lmd: new Date().getTime()
        }
*/
      this.editedItem = null
    },
    handleAddMenu () {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
    },
  },
}
</script>

