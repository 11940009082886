<template>
      <v-card v-if="survey">
        <v-toolbar
          dark
          color="primary"
          :elevation="0"
        >
          <v-toolbar-title>{{ !survey.ID ? 'Crear' : `Editar "${survey.Label.es}"`  }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-layout wrap >
          <v-flex xs12 style="padding: 20px 20px 5px 20px">
            <translate
              v-model="survey.Label"
              :languages="languages"
              :label="$t('booking.name', locale)"
              @input="handleChangeIsModified"
              type="text"
            />
          </v-flex>
          <v-flex xs12>
            <action-buttons
              :id="survey.ID"
              :onDeleteItem="showDelete ? (v) => { handleDelete(v) }:null"
              :onSaveItem="handleSave"
              :onClose="onClose"
              :locale="locale"
            />
          </v-flex>
        </v-layout>
      </v-card>
</template>
<script>
import api from '@/services/api'
import Translate from '@/components/Translate'
import ActionButtons from '@/components/ActionButtons'

export default {
  components: {
    Translate,
    ActionButtons,
  },
  props: {
    newItemParentID: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      required: {},
    },
    onClose: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      showDelete: false,
      locale: 'es',
      languages: [ 'es', 'en', 'de', 'fr'],
      dialog: false,
      survey: null,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  watch: {
    item () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.showDelete = this.item.ID !== null
      this.survey = JSON.parse(JSON.stringify(this.item))
    },
    handleChangeIsModified () {

    },
    handleDelete (v) {
      api.deleteItem('turismo', `v1/private/surveys/`, v)
        .then(() => {
          this.onClose('delete', this.survey)
        })
    },
    handleSave () {
      if (this.survey.ID) {
        api.updateItem('turismo', `v1/private/surveys/`, this.survey.ID, this.survey)
          .then(() => {
            this.onClose('update', this.survey)
          })
      } else {
        api.addItem('turismo', `v1/private/surveys/`, this.survey)
          .then(() => {
            this.onClose('add', this.survey)
          })
      }
    },
  },
}
</script>


